
import { CourseData } from "@planetadeleste/vue-mc-learning";
import { Component, Prop, Vue } from "vue-property-decorator";
import { get } from "lodash";
import { OfferData } from "@planetadeleste/vue-mc-shopaholic";

@Component
export default class CoursePriceBlock extends Vue {
  @Prop(Object) readonly item!: CourseData;

  @Prop(Boolean) readonly recommended!: boolean;

  get offer(): OfferData | null {
    return get(this.item, "product.offers.0", null);
  }

  get price(): string | null {
    if (!this.offer) {
      return null;
    }

    return `${this.offer.currency} ${this.offer.price}`;
  }

  get oldPrice(): string | null {
    if (!this.offer) {
      return null;
    }

    return `${this.offer.currency} ${this.offer.old_price}`;
  }

  get free(): boolean {
    return get(this.item, "free", false);
  }
}
